import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { encode } from 'utils/encode';
import s from './SubscribeForm.scss';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Loader from '../../assets/svg/dot-loader.svg';
import { CheckMark } from '../check-mark/CheckMark';

export const SubscribeForm = ({ whiteText = false }: { whiteText?: boolean }) => {
  const [loading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const subscribeFormik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: Yup.object<any>({
      firstName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string().email().required(),
    }),
    onSubmit: () => {},
  });

  const handleSubmit = useMemo(
    () => async (e: React.SyntheticEvent) => {
      e.preventDefault();
      setIsLoading(true);
      trackCustomEvent({
        category: 'Marketing',
        action: 'Click',
        label: 'Subscribe',
      });
      try {
        await fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'subscribe',
            firstName: subscribeFormik.values.firstName,
            lastName: subscribeFormik.values.lastName,
            email: subscribeFormik.values.email,
          }),
        });
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(true);
        }, 500);
        subscribeFormik.resetForm();
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } catch (e) {
        console.log(e);
        subscribeFormik.setFieldError('email', `hmmm let's try that again`);
        setIsLoading(false);
      }
    },
    [subscribeFormik],
  );

  const getButtonContent = () => {
    if (loading) {
      return <Loader />;
    } else if (success) {
      return (
        <div className="flex_center_row">
          <span className="margin_right_8">Thanks!</span> {<CheckMark />}
        </div>
      );
    } else {
      return 'Sign Up';
    }
  };

  return (
    <div className={'stack_16'}>
      <div className={'stack_16'}>
        <p
          className={classNames(
            whiteText ? 'white_text' : undefined,
            'garamond_37',
            'text_align_center',
          )}
        >
          Subscribe!
        </p>
        <p
          className={classNames(
            whiteText ? 'white_text' : undefined,
            'brandon_18',
            'text_align_center',
          )}
        >
          Sign up with your email address to receive news and updates.
        </p>
      </div>
      <form
        data-netlify="true"
        name="subscribe"
        method="post"
        className={classNames(s.form, s.space_right)}
      >
        <input type="hidden" name="form-name" value="subscribe" />
        <input
          {...subscribeFormik.getFieldProps('firstName')}
          type="text"
          className={'brandon_15'}
          placeholder="First Name"
          id="name-input"
        />
        <input
          {...subscribeFormik.getFieldProps('lastName')}
          type="text"
          className={'brandon_15'}
          placeholder="Last Name"
        />
        <input
          {...subscribeFormik.getFieldProps('email')}
          type="email"
          className={'brandon_15'}
          placeholder="Email"
          id="email-input"
        />

        <button
          className={classNames(whiteText ? 'button_white' : 'button')}
          disabled={!subscribeFormik.isValid || !subscribeFormik.dirty || loading}
          onClick={handleSubmit}
          style={{ minWidth: '145px' }}
        >
          {getButtonContent()}
        </button>
      </form>
    </div>
  );
};
