import { Link } from 'gatsby';
import React, { useRef, useState } from 'react';

import s from './Nav.scss';
import classNames from 'classnames';
import logo from '../../assets/images/logo.png';
import Logo from '../../assets/svg/logo.svg';
import Arrow from 'assets/svg/carrot.svg';
import { useScrollPosition } from 'hooks/useScrollPosition';

interface NavProps {
  whiteText?: boolean;
}

export const Nav = ({ whiteText = false }: NavProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const input = useRef<HTMLInputElement>(null);
  const mobileNav = useRef<HTMLDivElement>(null);

  const toggle = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (input && input.current) {
      const current = input.current.checked;
      input.current.checked = !current;
    }
  };

  const toggleWithTrip = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (input && input.current) {
      const current = input.current.checked;
      input.current.checked = !current;
    }
    setIsOpen(!isOpen);
  };

  useScrollPosition(
    ({ currPos }) => {
      if (mobileNav && mobileNav.current) {
        const isGrey = Math.abs(currPos.y) > mobileNav.current?.clientHeight;
        if (isGrey !== scrolled) setScrolled(isGrey);
      }
    },
    [scrolled],
    null,
    false,
    300,
  );

  return (
    <>
      <nav
        role="navigation"
        className={classNames(
          s.mobileNav,
          scrolled
            ? location.pathname === '/small-group-tour'
              ? s.scrolledWhite
              : s.scrolled
            : undefined,
        )}
        ref={mobileNav}
      >
        <div id={s.menuToggle}>
          <input type="checkbox" ref={input} />
          <span className={classNames(whiteText ? s.whiteSpan : undefined)}></span>
          <span id={s.span2} className={classNames(whiteText ? s.whiteSpan : undefined)}></span>
          <span className={classNames(whiteText ? s.whiteSpan : undefined)}></span>
          <ul id={s.menu}>
            <div className={s.mobile_logo_box}>
              <img
                src={logo}
                alt="ruadeseo"
                className={classNames(whiteText ? 'white_text' : 'grey_text', 'margin_bottom_16')}
              />
            </div>
            <li onClick={toggle}>
              <Link activeClassName="active_nav" className="nav_link" to="/">
                Home
              </Link>
            </li>
            {/* <li onClick={toggle}>
              <Link activeClassName="active_nav" className="nav_link" to="/about-us">
                About Us
              </Link>
            </li> */}
            <li onClick={() => setIsOpen(!isOpen)} className={s.dropDown}>
              <p className="nav_link">Tours</p>
              <span className={classNames(isOpen ? s.arrowOpen : undefined, s.arrow)}>
                <Arrow />
              </span>
            </li>
            <div className={classNames(s.indent, isOpen ? s.isOpen : s.isClosed)}>
              <li onClick={toggleWithTrip}>
                <Link
                  activeClassName="active_nav"
                  className="nav_link"
                  to="/valle-de-guadalupe-tours"
                >
                  Private Tours
                </Link>
              </li>
              <li onClick={toggleWithTrip}>
                <Link
                  activeClassName="active_nav"
                  className="nav_link"
                  to="/valle-de-guadalupe-public-tours"
                >
                  Public Tours
                </Link>
              </li>
              <li onClick={toggleWithTrip}>
                <Link
                  activeClassName="active_nav"
                  className="nav_link"
                  to="/valle-de-guadalupe-two-day"
                >
                  Two Day
                </Link>
              </li>
              <li onClick={toggleWithTrip}>
                <Link activeClassName="active_nav" className="nav_link" to="/baja-california-trips">
                  Multi Day
                </Link>
              </li>
            </div>
            <li>
              <a
                target="__blank"
                href="https://www.yelp.com/biz/ruadeseo-san-diego-6"
                className={classNames('nav_link', whiteText ? 'white_text' : undefined)}
              >
                Reviews
              </a>
            </li>
            <li onClick={toggle}>
              <Link activeClassName="active_nav" className="nav_link" to="/faq">
                FAQs
              </Link>
            </li>
            <li onClick={toggle}>
              <Link activeClassName="active_nav" className="nav_link" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
          <Link to="/" className={s.logo_end}>
            <Logo className={classNames(whiteText ? 'white_text' : 'grey_text')} />
          </Link>
        </div>
      </nav>

      <div className={classNames(s.nav_wrapper, whiteText ? 'white_text' : undefined)}>
        <div className={s.box}>
          <Link
            activeClassName="active_nav"
            className={classNames('nav_link', whiteText ? 'white_text' : undefined)}
            to="/"
          >
            Home
          </Link>
          {/* <Link
            activeClassName="active_nav"
            className={classNames('nav_link', whiteText ? 'white_text' : undefined)}
            to="/about-us"
          >
            About Us
          </Link> */}
          <div className={s.dropDownContainer}>
            <Link
              activeClassName="active_nav"
              className={classNames('nav_link', whiteText ? 'white_text' : undefined)}
              to="/valle-de-guadalupe-tours"
            >
              Tours
            </Link>
            <div className={classNames(whiteText ? s.dropDownContentWhite : s.dropDownContent)}>
              <Link
                activeClassName="active_nav"
                className={classNames('nav_link', whiteText ? 'white_text' : undefined)}
                to="/valle-de-guadalupe-tours"
              >
                Private Tours
              </Link>
              <Link
                activeClassName="active_nav"
                className={classNames('nav_link', whiteText ? 'white_text' : undefined)}
                to="/valle-de-guadalupe-public-tours"
              >
                Public Tours
              </Link>
              {/* <Link
                activeClassName="active_nav"
                className={classNames('nav_link', whiteText ? 'white_text' : undefined)}
                to="/public-trip"
              >
                Public Daycation
              </Link> */}
              <Link
                activeClassName="active_nav"
                className={classNames('nav_link', whiteText ? 'white_text' : undefined)}
                to="/valle-de-guadalupe-two-day"
              >
                Two Day
              </Link>
              <Link
                activeClassName="active_nav"
                className={classNames('nav_link', whiteText ? 'white_text' : undefined)}
                to="/baja-california-trips"
              >
                Multi Day
              </Link>
            </div>
          </div>
        </div>

        <Link to="/">
          <Logo
            style={{ marginLeft: '80px' }}
            className={classNames(whiteText ? 'white_text' : 'grey_text', 'margin_bottom_32')}
          />
        </Link>

        <div className={s.box}>
          <Link
            activeClassName="active_nav"
            className={classNames('nav_link', whiteText ? 'white_text' : undefined)}
            to="/faq"
            style={{ display: 'inline-block', paddingBottom: '32px' }}
          >
            FAQs
          </Link>
          <Link
            activeClassName="active_nav"
            className={classNames('nav_link', whiteText ? 'white_text' : undefined)}
            to="/contact"
            style={{ display: 'inline-block', paddingBottom: '32px' }}
          >
            Contact
          </Link>
          <a
            href="https://www.yelp.com/biz/ruadeseo-san-diego-6"
            target="__blank"
            className={classNames('nav_link', whiteText ? 'white_text' : undefined)}
          >
            Reviews
          </a>
        </div>
      </div>
    </>
  );
};
