import React, { useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import s from './ContactForm.scss';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Loader from '../../assets/svg/dot-loader.svg';
import { CheckMark } from '../check-mark/CheckMark';
import { useLazyQuery } from '@apollo/client';
import { CONTACT_FORM } from 'apollo/queries';

export const ContactForm = () => {
  const [success, setSuccess] = useState(false);
  const [sendContact, { loading, error, data }] = useLazyQuery(CONTACT_FORM);

  useEffect(() => {
    if (data) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        contactFormik.resetForm();
      }, 3000);
    }
    if (error) {
      setSuccess(false);
      contactFormik.setFieldError('email', `hmmm let's try that again`);
      contactFormik.resetForm();
    }
  }, [data, error]);

  const contactFormik = useFormik({
    initialValues: {
      name: '',
      textareaMessage: '',
      email: '',
    },
    validationSchema: Yup.object<any>({
      name: Yup.string(),
      textareaMessage: Yup.string().max(500),
      email: Yup.string().email().required(),
    }),
    onSubmit: () => {},
  });

  const handleSubmit = useMemo(
    () => async (e: React.SyntheticEvent) => {
      e.preventDefault();
      trackCustomEvent({
        category: 'Marketing',
        action: 'Click',
        label: 'Contact',
      });
      try {
        sendContact({
          variables: {
            contactFormInput: {
              name: contactFormik.values.name,
              email: contactFormik.values.email,
              message: contactFormik.values.textareaMessage,
            },
          },
        });
      } catch (e) {
        console.log(e);
        contactFormik.setFieldError('email', `hmmm let's try that again`);
      }
    },
    [contactFormik],
  );

  const getButtonContent = () => {
    if (loading) {
      return <Loader />;
    } else if (success) {
      return (
        <div className="flex_center_row">
          <span className="margin_right_8">Thanks!</span> {<CheckMark />}
        </div>
      );
    } else {
      return 'Send';
    }
  };

  return (
    <form name="contact" method="post" className={classNames('box', s.space_right)}>
      <input type="hidden" name="form-name" value="contact" />
      <div className="stack_16">
        <div className="stack_24">
          <div className={(s.box, s.form)}>
            <div className={classNames('flex_start_column', 'stack_8')}>
              <label className={'brandon_15'} htmlFor="name">
                Name
              </label>
              <input
                {...contactFormik.getFieldProps('name')}
                type="text"
                id="name-input"
                className={classNames('brandon_15', s.space_right)}
                placeholder="Name"
              />
            </div>
            <div className={classNames('flex_start_column', 'stack_8')}>
              <label className={'brandon_15'} htmlFor="email">
                Email
              </label>
              <input
                {...contactFormik.getFieldProps('email')}
                type="email"
                id="email-input"
                className={'brandon_15'}
                placeholder="Email"
              />
            </div>
          </div>
          <textarea
            id="contact-input"
            className={classNames('brandon_15', s.textarea)}
            placeholder="Send us a message and we will be in touch!"
            {...contactFormik.getFieldProps('textareaMessage')}
          ></textarea>
        </div>
        <button
          className={classNames('button', success ? s.success : undefined)}
          style={{ width: '100%' }}
          disabled={!contactFormik.isValid || !contactFormik.dirty || loading}
          onClick={handleSubmit}
        >
          {getButtonContent()}
        </button>
      </div>
    </form>
  );
};
