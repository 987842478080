import { gql } from '@apollo/client';

export const CONTACT_FORM = gql`
  query Contact($contactFormInput: ContactFormInput!) {
    contact(contactFormInput: $contactFormInput)
  }
`;

export const QUOTE_FORM = gql`
  query QuoteForm($quoteRequestInput: QuoteRequestInput!) {
    quoteRequest(quoteRequestInput: $quoteRequestInput)
  }
`;
