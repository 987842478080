import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import Google from '../../assets/svg/google.svg';
import Yelp from '../../assets/svg/yelp.svg';
import Instagram from '../../assets/svg/instagram.svg';
import Facebook from '../../assets/svg/facebook.svg';
import TripAdvisor from '../../assets/svg/tripadvisor.svg';

import { helmet } from '../../utils/helmet';
import { Footer } from '../footer/Footer';
import { Nav } from '../';
import s from './AppLayout.scss';

interface AppLayoutProps {
  children: ReactNode;
  pageContext: any;
}

// tslint:disable no-default-export
export default ({ children, pageContext }: AppLayoutProps) => {
  const whiteNav =
    pageContext.layout === 'book' ||
    pageContext.layout === 'about' ||
    pageContext.layout === 'home' ||
    pageContext.layout === 'multi' ||
    pageContext.layout === 'weekend' ||
    pageContext.layout === 'public' ||
    pageContext.layout === 'horse';

  return (
    <div className={s.layout}>
      <Helmet {...helmet}></Helmet>
      <Nav whiteText={whiteNav} />
      {children}
      <Footer
        logo={<div>ruadeseo</div>}
        social={[
          { icon: <Google />, to: 'https://g.page/r/CVUTH963p9IBEAE' },
          { icon: <Yelp />, to: 'https://www.yelp.com/biz/ruadeseo-san-diego-3' },
          { icon: <Instagram />, to: 'https://www.instagram.com/ruadeseo' },
          { icon: <Facebook />, to: 'https://www.facebook.com/Ruadeseo-102230698813036' },
          {
            icon: <TripAdvisor />,
            to:
              'https://www.tripadvisor.com/Attraction_Review-g60750-d23461328-Reviews-Ruadeseo-San_Diego_California.html',
          },
        ]}
      />
    </div>
  );
};
