import React from 'react';
import classNames from 'classnames';

import s from './TextStackHero.scss';

interface TextStackHeroProps {
  heading: string;
  description: string;
  white?: boolean;
  maxWidth?: string;
}

export const TextStackHero = ({
  heading,
  description,
  white = false,
  maxWidth,
}: TextStackHeroProps) => (
  <div className={s.box}>
    <div className={'stack_32'}>
      <div style={{ maxWidth }} className={s.max_width}>
        <h1
          className={classNames('garamond_61', 'text_align_left', white ? 'white_text' : undefined)}
        >
          {heading}
        </h1>
      </div>
      <div style={{ maxWidth }} className={s.max_width_sub}>
        <h2
          className={classNames('brandon_18', 'text_align_left', white ? 'white_text' : undefined)}
        >
          {description}
        </h2>
      </div>
    </div>
  </div>
);
