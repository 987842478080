import React from 'react';

import s from './Photo6x6.scss';
import classNames from 'classnames';
import { TextStackBody } from 'components/text-stack-body/TextStackBody';
import Wine from '../../assets/svg/glass-with-wine.svg';
import Van from '../../assets/svg/van.svg';
import Passport from '../../assets/svg/passport.svg';

import { chunkArray } from 'utils/chunkArray';

interface PhotoTextProps {
  topRight: string;
  topLeft: string;
  bottomMiddle: string;
  topLeftHeading: string;
  topLeftDescription: string;
  topRightHeading: string;
  topRightDescription: string;
  bottomMiddleHeading: string;
  bottomMiddleDescription: string;
}
interface Photo6x6Props {
  photoTextInfo?: PhotoTextProps;
  photos?: string[];
  boxWidthAndHeight?: string;
}

export const Photo6x6 = ({ photoTextInfo, photos, boxWidthAndHeight }: Photo6x6Props) => {
  if (photos?.length) {
    const rows = chunkArray(
      photos.map((photo) => {
        return (
          <div
            key={photo}
            className={s.box}
            style={{
              backgroundImage: `url(${photo})`,
            }}
          />
        );
      }),
      3,
    );
    return (
      <div className={s.wrapper}>
        {rows.map((row) => {
          return row;
        })}
      </div>
    );
  } else if (photoTextInfo) {
    const {
      topRight,
      topLeft,
      bottomMiddle,
      topLeftHeading,
      topLeftDescription,
      topRightHeading,
      topRightDescription,
      bottomMiddleHeading,
      bottomMiddleDescription,
    } = photoTextInfo;

    return (
      <div className={s.wrapper}>
        <div
          className={s.box}
          style={{
            backgroundImage: `url(${topRight})`,
            width: boxWidthAndHeight,
            height: boxWidthAndHeight,
          }}
        />
        <div
          style={{
            width: boxWidthAndHeight,
            height: boxWidthAndHeight,
            padding: '32px',
          }}
          className={classNames(s.box, s.emerald)}
        >
          <div className={'stack_16'}>
            <Van />
            <TextStackBody
              white={false}
              heading={topLeftHeading}
              description={topLeftDescription}
            />
          </div>
        </div>
        <div
          className={s.box}
          style={{
            backgroundImage: `url(${topLeft})`,
            width: boxWidthAndHeight,
            height: boxWidthAndHeight,
          }}
        />
        <div
          style={{
            width: boxWidthAndHeight,
            height: boxWidthAndHeight,
            padding: '32px',
          }}
          className={classNames(s.box, s.clay)}
        >
          <div className={'stack_16'}>
            <Passport />
            <TextStackBody
              white={false}
              heading={topRightHeading}
              description={topRightDescription}
            />
          </div>
        </div>
        <div
          className={s.box}
          style={{
            backgroundImage: `url(${bottomMiddle})`,
            width: boxWidthAndHeight,
            height: boxWidthAndHeight,
          }}
        />
        <div
          style={{
            width: boxWidthAndHeight,
            height: boxWidthAndHeight,
            padding: '32px',
          }}
          className={classNames(s.box, s.terracotta)}
        >
          <div className={'stack_16'}>
            <Wine />
            <TextStackBody
              white={false}
              heading={bottomMiddleHeading}
              description={bottomMiddleDescription}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
};
