import React from 'react';

import s from './TextStackBody.scss';
import classNames from 'classnames';

interface TextStackBodyProps {
  heading: string;
  description: string;
  white?: boolean;
}

export const TextStackBody = ({ heading, description, white = false }: TextStackBodyProps) => (
  <div className={s.box}>
    <div className={s.max_width}>
      <div className={'stack_8'}>
        <h1 className={classNames('garamond_23', white ? 'white_text' : undefined)}>{heading}</h1>
        <h2 className={classNames('brandon_14', white ? 'white_text' : undefined)}>
          {description}
        </h2>
      </div>
    </div>
  </div>
);
