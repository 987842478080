import React from 'react';

import s from './TextStackButton.scss';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { OutboundLink, trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

interface GoogleAnalytics {
  eventAction?: string;
  eventCategory?: string;
  eventLabel?: string;
}

interface TextStackButtonProps {
  heading: string;
  description: string;
  callout: string;
  buttonText: string;
  white?: boolean;
  right?: boolean;
  buttonLink?: string;
  openExternal?: boolean;
  analytics?: GoogleAnalytics;
  isRzdy?: boolean;
  anchor?: boolean;
}

export const TextStackButton = ({
  heading,
  description,
  callout,
  buttonText,
  buttonLink,
  white = false,
  right = false,
  openExternal = false,
  analytics,
  isRzdy,
  anchor,
}: TextStackButtonProps) => {
  const renderButton = () => {
    if (openExternal) {
      return (
        <OutboundLink
          onClick={(e) => {
            e.preventDefault();
            window.open(buttonLink);
          }}
          eventAction={analytics?.eventAction}
          eventCategory={analytics?.eventCategory}
          eventLabel={analytics?.eventLabel}
          style={{ display: 'inline-block' }}
          className={classNames('button', white ? 'button_white' : undefined)}
        >
          {buttonText}
        </OutboundLink>
      );
    } else if (isRzdy) {
      return (
        <div
          onClick={(e: React.SyntheticEvent) => {
            trackCustomEvent({
              category: analytics?.eventCategory || '',
              action: analytics?.eventAction || '',
              label: analytics?.eventLabel || '',
            });
            // window?.fbq('track', 'InitiateCheckout');
          }}
        >
          <a
            style={{ display: 'inline-block' }}
            id="button-booking"
            className={classNames(
              'button button-booking rezdy rezdy-modal',
              white ? 'button_white' : undefined,
            )}
            href={buttonLink}
          >
            {buttonText}
          </a>
        </div>
      );
    } else if (anchor) {
      return (
        <AnchorLink to={buttonLink!}>
          <button className={classNames('button', white ? 'button_white' : undefined)}>
            {buttonText}
          </button>
        </AnchorLink>
      );
    } else {
      return (
        <Link to={buttonLink!}>
          <button className={classNames('button', white ? 'button_white' : undefined)}>
            {buttonText}
          </button>
        </Link>
      );
    }
  };

  return (
    <div className={s.box}>
      <div className={classNames(s.max_width, right ? 'text_align_right' : 'text_align_left')}>
        <div className={'stack_16'}>
          <h1 className={classNames('brandon_20', white ? 'white_text' : undefined)}>{callout}</h1>
          <h2 className={classNames('garamond_61', white ? 'white_text' : undefined)}>{heading}</h2>
        </div>
        <div className={'stack_32'}>
          <h3 className={classNames('brandon_18', white ? 'white_text' : undefined)}>
            {description}
          </h3>
          {renderButton()}
        </div>
      </div>
    </div>
  );
};
