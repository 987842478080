import React from 'react';
import Star from '../../assets/svg/star.svg';

import s from './YelpCard.scss';

type Review = {
  id: string;
  url: string;
  text: string;
  rating: number;
  time_created: number;
  user: {
    id: string;
    profile_url: string;
    image_url: string | null;
    name: string;
  };
};

interface ReviewProp {
  review: Review;
}

export const YelpCard = ({ review }: ReviewProp) => {
  return (
    <div className={s.card}>
      <div className={s.profile}>
        <div
          className={s.avatar}
          style={{ backgroundImage: `url(${review.user.image_url})` }}
        ></div>
        <div className={s.userInfo}>
          <p className="brandon_18_bold">{review.user.name}</p>
          <div className={s.rating}>
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </div>
        </div>
      </div>
      <div className={s.reviewText}>
        <p className="brandon_14">{review.text}</p>
        <p className="brandon_14 margin_top_8">
          <a href={review.url} target="__blank">
            Read more
          </a>
        </p>
      </div>
    </div>
  );
};
