import React from 'react';

import s from './PhotoOverlap.scss';

interface PhotoOverlapProps {
  mainAsset: string;
  secondaryAsset: string;
  right?: boolean;
}

export const PhotoOverlap = ({ mainAsset, secondaryAsset, right }: PhotoOverlapProps) => (
  <div className={s.wrapper}>
    <div className={s.mainAsset} style={{ backgroundImage: `url(${mainAsset})` }}></div>
    <div
      className={right ? s.secondaryAssetRight : s.secondaryAsset}
      style={{ backgroundImage: `url(${secondaryAsset})` }}
    ></div>
  </div>
);
