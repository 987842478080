import React from 'react';
import classNames from 'classnames';
import s from './Text6x6.scss';
import { Link } from 'gatsby';

interface Text {
  title: string;
  subtitle: string;
}

interface Text6x6Props {
  textInfo: Text[];
  colors?: Array<string>;
  whiteText?: boolean;
}

const defaultColors = [s.emerald, s.slate, s.slate, s.emerald];

export const Text6x6 = ({ textInfo, colors = defaultColors, whiteText }: Text6x6Props) => {
  const twoByTwoCss = textInfo.length === 4 ? s.box4 : s.box;

  return (
    <div className={s.wrapper}>
      {textInfo.map((info, index) => {
        return (
          <div key={index} className={classNames(s.box, colors[index])}>
            <div className={classNames('stack_16', s.textWrapper)}>
              <h1 className={classNames('garamond_37', whiteText ? 'white_text' : undefined)}>
                {info.title}
              </h1>
              <h2 className={classNames('brandon_22', whiteText ? 'white_text' : undefined)}>
                {info.subtitle}
              </h2>
            </div>
          </div>
        );
      })}
      <p className="brandon_18">
        <Link to="/faq">See more →</Link>
      </p>
    </div>
  );
};
