import React, { useState } from 'react';

import s from './BookingWidget.scss';
import classNames from 'classnames';

type Widget =
  | 'public'
  | 'private'
  | 'horse'
  | 'multi'
  | 'weekend'
  | 'weekday'
  | 'two'
  | 'four'
  | 'six';

interface BookingWidgetProps {
  type: Widget;
}
export const BookingWidget = ({ type }: BookingWidgetProps) => {
  const [loading, setLoading] = useState(true);

  let url;
  let height;
  switch (type) {
    case 'two':
      url = 'https://ruadeseo.rezdy.com/calendarWidget/480907?iframe=true';
      height = '620px';
      break;
    case 'four':
      url = 'https://ruadeseo.rezdy.com/calendarWidget/480912?iframe=true';
      height = '620px';
      break;
    case 'six':
      url = 'https://ruadeseo.rezdy.com/calendarWidget/480913?iframe=true';
      height = '620px';
      break;
    case 'private':
      url = 'https://ruadeseo.rezdy.com/calendarWidget/450975?iframe=true';
      height = '600px';
      break;
    case 'weekday':
      url = 'https://ruadeseo.rezdy.com/calendarWidget/462692?iframe=true';
      height = '600px';
      break;
    case 'multi':
      url = 'https://ruadeseo.rezdy.com/calendarWidget/458269?iframe=true';
      height = '500px';
      break;
    case 'weekend':
      url = 'https://ruadeseo.rezdy.com/calendarWidget/464323?iframe=true';
      height = '620px';
      break;
  }

  const finishdLoading = () => {
    setLoading(false);
  };

  return (
    <div className={classNames(s.rzdyWrapper, loading ? s.animatedBackground : undefined)}>
      <iframe
        seamless
        width="300px"
        height={height}
        frameBorder="0"
        className="rezdy rzdy-iframe"
        src={url}
        onLoad={finishdLoading}
      ></iframe>
    </div>
  );
};
