import React from 'react';
import s from './CheckMark.scss';

export const CheckMark = () => {
  return (
    <svg className={s.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle className={s.checkmark__circle} cx="26" cy="26" r="25" fill="none" />
      <path className={s.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  );
};
