import React, { ReactNode } from 'react';

import s from './Footer.scss';
import classNames from 'classnames';

import { Link } from 'gatsby';

interface Social {
  icon: ReactNode;
  to: string;
}

interface FooterProps {
  logo: ReactNode;
  social: Social[];
}

const images = [
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/finca-francisco.jpg?width=500',
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/relieve.jpg?width=500',
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/relieve-pour.jpg?width=500',
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/selfie-color.jpg?width=500',
];

export const Footer = ({ social }: FooterProps) => (
  <div className={s.footer}>
    <div className={s.wrapper}>
      <div style={{ marginRight: '16px' }}>
        <p
          className={classNames(
            'garamond_37',
            'white_text',
            'text_align_right',
            'margin_bottom_16',
          )}
        >
          Let Your
        </p>
        <ul className={s.footer_nav_list}>
          <li>
            <Link className={'footer_link'} to="/">
              Home
            </Link>
          </li>
          {/* <li>
            <Link className={'footer_link'} to="/about-us">
              About Us
            </Link>
          </li> */}
          {/* <li>
            <a className={'footer_link'} href="https://ruadeseo.rezdy.com">
              Book
            </a>
          </li> */}
          <li>
            <Link className={'footer_link'} to="/valle-de-guadalupe-tours">
              Private Wine Tours
            </Link>
          </li>
          <li>
            <Link className={'footer_link'} to="/valle-de-guadalupe-public-tours">
              Public Wine Tours
            </Link>
          </li>
          <li>
            <Link className={'footer_link'} to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div className={classNames('stack_32', 'flex_start_column')}>
        <p className={classNames('garamond_37', 'white_text', 'text_align_right')}>
          Adventure Begin
        </p>
        <div className={s.imageWrapper}>
          {images.map((image, index) => {
            return (
              <div
                key={index}
                className={s.footer_img}
                style={{ backgroundImage: `url(${image})` }}
              />
            );
          })}
        </div>
        <ul className={s.footer_social_list}>
          {social.map((item) => (
            <li key={item.to} className={s.footer__item}>
              <a href={item.to} target="_blank" rel="noopener noreferrer">
                {item.icon}
              </a>
            </li>
          ))}
        </ul>
        <a
          className="brandon_12 white_text"
          target="__blank"
          style={{ alignSelf: 'flex-end' }}
          href="https://ruadeseo.s3.us-west-1.amazonaws.com/Ruadeseo_PP_5.26.pdf"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  </div>
);
