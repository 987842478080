// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-baja-california-trips-tsx": () => import("./../../../src/pages/baja-california-trips.tsx" /* webpackChunkName: "component---src-pages-baja-california-trips-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-summer-tsx": () => import("./../../../src/pages/summer.tsx" /* webpackChunkName: "component---src-pages-summer-tsx" */),
  "component---src-pages-valle-de-guadalupe-public-tours-tsx": () => import("./../../../src/pages/valle-de-guadalupe-public-tours.tsx" /* webpackChunkName: "component---src-pages-valle-de-guadalupe-public-tours-tsx" */),
  "component---src-pages-valle-de-guadalupe-tours-tsx": () => import("./../../../src/pages/valle-de-guadalupe-tours.tsx" /* webpackChunkName: "component---src-pages-valle-de-guadalupe-tours-tsx" */),
  "component---src-pages-valle-de-guadalupe-two-day-tsx": () => import("./../../../src/pages/valle-de-guadalupe-two-day.tsx" /* webpackChunkName: "component---src-pages-valle-de-guadalupe-two-day-tsx" */),
  "component---src-pages-wine-tour-tsx": () => import("./../../../src/pages/wine-tour.tsx" /* webpackChunkName: "component---src-pages-wine-tour-tsx" */)
}

