const { wrapRootElement } = require('./src/apollo/client');

exports.onRouteUpdate = ({ location }) => {};
exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    require(`intersection-observer`);
    // console.log(`# IntersectionObserver is polyfilled!`);
  }
};

exports.wrapRootElement = wrapRootElement;
