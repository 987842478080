import React, {useState, useEffect} from 'react';
import AppLayout from 'components/app-layout/AppLayout';

export default ({ children, pageContext }) => {
  const [country, setCountry] = useState();

  useEffect(() => {
    try {
      fetch('https://api.ipregistry.co/?key=u6ndvb1pxrtlfrj9')
        .then(function (response) {
          return response.json();
        })
        .then(function (payload) {
          setCountry(payload.location.country.name);
        });
    } catch (e) {
      console.log(e);
    }
  }, [])

  if (country === 'Mexico') {
    return null;
  }
  if (pageContext.layout === 'special') {
    return <>{children}</>;
  }
  return <AppLayout pageContext={pageContext}>{children}</AppLayout>;
};
